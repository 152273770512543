import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

import { useGetStripeAccountStatus } from '@/api/endpoints/stripeConnect/useGetStripeAccountStatus';
import { useGetStripeDashboardLink } from '@/api/endpoints/stripeConnect/useGetStripeDashboardLink';
import { useOnboardStripeConnect } from '@/api/endpoints/stripeConnect/useOnboardStripeConnect';

const StripeConnectSettings: React.FC = () => {
  const { data: status, isLoading: isLoadingStatus, error } = useGetStripeAccountStatus();
  const { mutate: onboard, isPending: isOnboarding } = useOnboardStripeConnect();
  const { mutate: getDashboardLink, isPending: isLoadingDashboardLink } =
    useGetStripeDashboardLink();

  const handleOnboard = () => {
    onboard();
  };

  const handleViewDashboard = () => {
    getDashboardLink();
  };

  const renderContent = () => {
    if (isLoadingStatus) {
      return <CircularProgress size={24} />;
    }

    if (error || !status) {
      return <Typography color="error">Could not load Stripe account status.</Typography>;
    }

    let statusText = 'Not Connected';
    let canOnboard = true;
    let canViewDashboard = false;

    if (status.onboardingComplete && status.chargesEnabled && status.payoutsEnabled) {
      statusText = 'Active';
      canOnboard = false;
      canViewDashboard = true;
    } else if (status.detailsSubmitted || status.onboardingComplete) {
      statusText = 'Onboarding Incomplete / Verification Pending';
      canOnboard = true;
      canViewDashboard = false;
    }

    return (
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography>Status: {statusText}</Typography>
        {canOnboard && (
          <Button
            variant="contained"
            onClick={handleOnboard}
            disabled={isOnboarding || isLoadingDashboardLink}
          >
            {isOnboarding ? <CircularProgress size={24} /> : 'Connect Stripe Account'}
          </Button>
        )}
        {canViewDashboard && (
          <Button
            variant="outlined"
            onClick={handleViewDashboard}
            disabled={isOnboarding || isLoadingDashboardLink}
          >
            {isLoadingDashboardLink ? <CircularProgress size={24} /> : 'View Stripe Dashboard'}
          </Button>
        )}
      </Stack>
    );
  };

  return (
    <Card>
      <CardHeader
        title="Stripe Payouts"
        subheader="Connect your Stripe account to receive rent payments."
      />
      <CardContent>
        <Box>{renderContent()}</Box>
      </CardContent>
    </Card>
  );
};

export default StripeConnectSettings;
