import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { GetRentDetailsResponse } from '@/types/dto/rent-unit.dto';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

const METHOD = 'GET';
const ENDPOINT = '/unit/:id/rent-details'; // Matches backend controller route

// Function to generate the query key, including the unitId
export const getQueryKeyGetRentDetails = (unitId: number) =>
  getQueryKey(METHOD, ENDPOINT, { id: unitId });

export const useGetRentDetails = (unitId: number, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    // Use the dynamic query key function
    queryKey: getQueryKeyGetRentDetails(unitId),
    queryFn: async () => {
      try {
        const data = await handleApiCall<GetRentDetailsResponse | null>( // Expect null if not found
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, { id: unitId.toString() })
        );

        // If no data (404 or null from backend), return null
        if (!data) {
          return null;
        }

        // Convert amount from cents to dollars for form display
        return {
          ...data,
          amount: data.amount / 100,
        };
      } catch (err) {
        enqueueSnackbar('Failed to fetch rent details.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(err);
        return null; // Return null on error
      }
    },
    enabled: enabled && !!session && !!unitId, // Ensure unitId is valid
    // Optional: Add staleTime or cacheTime if needed
    // staleTime: 5 * 60 * 1000, // 5 minutes
  });
};
