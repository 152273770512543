import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { SubscriptionModel } from './useGetSubscription';

export type ApiResponse_Cancel_Subscription = {
  cancelUrl: string;
  subscriptionId: string;
};

export const useCancelSubscription = (
  id: SubscriptionModel['id'],
  stateFns?: MutationStateFns<ApiResponse_Cancel_Subscription>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async () =>
      handleApiCall<ApiResponse_Cancel_Subscription, { subscriptionId: string }>(
        session,
        'GET',
        `paddle/subscription/cancel/${id}`
      ),
    onSuccess: data => {
      // Instead of showing success message, redirect to Paddle's cancellation URL
      if (data.cancelUrl) {
        // Open the Paddle cancellation page in a new tab
        window.open(data.cancelUrl, '_blank');
      } else {
        enqueueSnackbar('Could not retrieve cancellation link', { variant: 'error' });
      }

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to get cancellation link', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
