import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

// Re-use the status query key for potential invalidation, though less critical here

interface DashboardLinkResponse {
  url: string; // Corrected property name to match backend response
}

const METHOD = 'POST';
const ENDPOINT = '/connect/dashboard-link';
export const MUTATION_KEY_GET_STRIPE_DASHBOARD_LINK = getQueryKey(METHOD, ENDPOINT);

export const useGetStripeDashboardLink = () => {
  const { session } = useAuth();
  // Removed unused queryClient

  return useMutation({
    mutationKey: MUTATION_KEY_GET_STRIPE_DASHBOARD_LINK,
    mutationFn: async () => {
      try {
        const data = await handleApiCall<DashboardLinkResponse>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {})
        );
        return data;
      } catch (err) {
        enqueueSnackbar('Failed to get Stripe dashboard link.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
      }
    },
    onSuccess: data => {
      // No query invalidation needed here

      if (data?.url) {
        // Check for the correct property 'url'
        // External redirect is appropriate here
        window.location.href = data.url; // Use the correct property 'url'
      } else {
        enqueueSnackbar('Failed to retrieve Stripe dashboard link URL.', { variant: 'error' });
      }
    },
  });
};
