import { Environment } from './environment';

export const environment: Environment = {
  sentryDsn:
    'https://f00fe29f2c0d752d703b60ef88c3dcdb@o4507788926976000.ingest.us.sentry.io/4507788938575872', // same for dev
  // api: 'https://terrai-431617.web.app/api',
  // api: 'https://terrai-ai-fastapi-development-356805368329.us-east4.run.app/api',
  // api: 'http://localhost:3000/api',
  api: '/api',
  direct: 'https://terrai-api-development-356805368329.us-east4.run.app/api',
  production: false,
  stage: 'development',
  streamChatApiKey: 'gusn9gas66bk',
  supabaseUrl: 'https://krivdnowuxtzpyuohwqb.supabase.co',
  supabaseAnonKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtyaXZkbm93dXh0enB5dW9od3FiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQzNTM1ODQsImV4cCI6MjAzOTkyOTU4NH0.hitvr6rmKqkzrCZhFtot-x86hvVe_xgh6RKvoX6Zt3g',
  posthogUrl: 'https://us.i.posthog.com',
  posthogKey: 'phc_OgDvEHvm8UQqnlBCjagAvOtjMMYW6bLYpB34bZlcf71', // this is a non-pord key for all other envs
  mapboxApiKey:
    'pk.eyJ1IjoibmlrZWVwIiwiYSI6ImNsenJhd241MzF5aWUyaXB4eXc5dmRmYWoifQ.cSKHS-JGEVky6zK-dsMMOQ', // same for all envs
  turnstileSiteKey: '0x4AAAAAAA5_Rhq0LpxfsRAb',
  paddleClientToken: 'test_486422f39f7bc42e4a173504a26',
};
