import { TextField, Stack, Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState, SyntheticEvent } from 'react';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { useAuth } from '@/context/AuthProvider';
import SubscriptionSection from '@/pages/property-manager-portal/subscription/SubscriptionSection';
import StripeConnectSettings from '@/pages/shared/StripeConnectSettings'; // Import the new component

import AccountTabPanel, { a11yProps } from './account/AccountTabPanel';
import FullNameForm from './account/FullNameForm';
import PasswordForm from './account/PasswordForm';
import PhoneForm from './account/PhoneForm';

const AccountPage: React.FC = () => {
  const { user, isPropertyManager } = useAuth();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Stack>
      <DashboardHeader title="Settings" />

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="account settings tabs">
            <Tab label="Contact details" {...a11yProps(0)} />
            <Tab label="Profile" {...a11yProps(1)} />
            <Tab label="Password" {...a11yProps(2)} />
            {isPropertyManager && <Tab label="Subscription" {...a11yProps(3)} />}
            {isPropertyManager && <Tab label="Payouts" {...a11yProps(4)} />} {/* Add Payouts tab */}
          </Tabs>
        </Box>

        <AccountTabPanel value={tabValue} index={0}>
          <Stack spacing={4}>
            <Box>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Telephone contact details
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Your phone number is used for account verification and important notifications. We
                will only call you regarding urgent matters related to your account.
              </Typography>
              <PhoneForm />
            </Box>

            <Box>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Email contact details
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Your email address is used for account login and receiving notifications. This email
                cannot be changed after registration.
              </Typography>
              <TextField label="Email" size="small" value={user?.email ?? ''} fullWidth disabled />
            </Box>
          </Stack>
        </AccountTabPanel>

        <AccountTabPanel value={tabValue} index={1}>
          <Box>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Profile
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Manage your personal information. Your name will be displayed across the platform and
              in notifications sent to other users.
            </Typography>
            <FullNameForm />
          </Box>
        </AccountTabPanel>

        <AccountTabPanel value={tabValue} index={2}>
          <Box>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Password
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Keep your account secure with a strong password. We recommend using a combination of
              letters, numbers, and special characters.
            </Typography>
            <PasswordForm />
          </Box>
        </AccountTabPanel>

        {isPropertyManager && (
          <AccountTabPanel value={tabValue} index={3}>
            <Box>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Subscription
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Manage your subscription details.
              </Typography>
              <SubscriptionSection />
            </Box>
          </AccountTabPanel>
        )}

        {/* Add TabPanel for Payouts */}
        {isPropertyManager && (
          <AccountTabPanel value={tabValue} index={4}>
            <Box>
              {/* Title and description can be adjusted */}
              <Typography variant="h5" sx={{ mb: 2 }}>
                Payout Settings
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Manage your Stripe connection to receive rent payments.
              </Typography>
              <StripeConnectSettings />
            </Box>
          </AccountTabPanel>
        )}
      </Box>
    </Stack>
  );
};

export default AccountPage;
