import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export interface SubscriptionModel {
  id: string;
  status: 'active' | 'trialing' | 'paused' | 'canceled' | 'past_due';
  current_billing_period: {
    ends_at: string;
    starts_at: string;
  };
  plan_name: string;
  price_id: string;
  price: number;
  currency: string;
  scheduled_cancellation?: boolean;
}

export type ApiResponse_GetSubscription = SubscriptionModel | null;

const METHOD = 'GET';
const ENDPOINT = 'paddle/subscription/current';
export const QUERY_KEY = () => getQueryKey(METHOD, ENDPOINT, {});

export const useGetSubscription = (enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetSubscription>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {})
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch subscription information.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
