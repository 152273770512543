import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { GenerateRentLinkDto, GenerateRentLinkResponse } from '@/types/dto/rent-unit.dto';

import { UnitModel } from '@/api/models/unit'; // Needed for unitId type
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation'; // Assuming MutationStateFns exists
import { useAuth } from '@/context/AuthProvider';

// Define specific request/response types following convention
export type ApiRequest_GenerateRentLink = GenerateRentLinkDto;
export type ApiResponse_GenerateRentLink = GenerateRentLinkResponse;

// Define Method and Endpoint constants
const METHOD = 'POST';
const ENDPOINT = 'unit/:id/generate-rent-link';

export const useGenerateRentLink = (
  unitId: UnitModel['id'], // Pass unitId directly
  stateFns?: MutationStateFns<ApiResponse_GenerateRentLink>
) => {
  const { session } = useAuth();

  return useMutation<
    ApiResponse_GenerateRentLink,
    AxiosError, // Use AxiosError for error type
    ApiRequest_GenerateRentLink // Data passed to mutationFn is the DTO
  >({
    mutationFn: async (data: ApiRequest_GenerateRentLink) =>
      handleApiCall<ApiResponse_GenerateRentLink, ApiRequest_GenerateRentLink>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: unitId.toString() }),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Rent subscription link generated', { variant: 'success' });
      // No query invalidation needed here typically, as we just get a URL
      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      // Check for the specific 404 error indicating incomplete onboarding
      if (
        err.response?.status === 404 &&
        typeof err.response?.data === 'object' &&
        err.response.data &&
        'message' in err.response.data &&
        typeof err.response.data.message === 'string' &&
        err.response.data.message.includes('does not have a fully onboarded Stripe Connect account')
      ) {
        enqueueSnackbar('Please complete Stripe onboarding before generating rent links.', {
          variant: 'warning',
        });
      } else {
        // Generic error message for other failures
        enqueueSnackbar('Failed to generate rent subscription link', { variant: 'error' });
      }

      // eslint-disable-next-line no-console
      console.error('Error generating rent link:', err.response?.data || err.message);
      stateFns?.onError?.(err);
    },
  });
};
